import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import {
  Image,
} from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import Layout from '../../../../components/layout'
import LatestNewsPage from '../LatestNews'
import { Helmet } from "react-helmet"
import { OutboundLink } from 'gatsby-plugin-gtag'

const DetailNewsTemplate = ({ data }) => {
  var image_1;
  var image_2;
  var image_3;

  if (data.strapiNews.image_content_1 === null) {
    image_1 = <div />;
  } else {
    image_1 = <Img fixed={data.strapiNews.image_content_1.childImageSharp.fixed} alt="900x500" className="image-detail-news lazyload" />;
  }

  if (data.strapiNews.image_content_2 === null) {
    image_2 = <div />;
  } else {
    image_2 = <Img fixed={data.strapiNews.image_content_2.childImageSharp.fixed} alt="900x500" className="image-detail-news lazyload" />;
  }

  if (data.strapiNews.image_content_3 === null) {
    image_3 = <div />;
  } else {
    image_3 = <Img fixed={data.strapiNews.image_content_3.childImageSharp.fixed} alt="900x500" className="image-detail-news lazyload" />;
  }

  return(
    <Layout pageInfo={{ pageName: "Detail News Page" }} sitePage="site-page">
      <Helmet defer={false}>
          <title>{data.strapiNews.title}</title>
          <meta name="description" content={data.strapiNews.title} />
      </Helmet>
      <div  className="wrapper-detail">
        <div className="wrapper-detail-news">
          <h1 className="title-detail-news">{data.strapiNews.title}</h1>
          <div className="author-social">
            <p className="author-date">{data.strapiNews.updated_at}<span className="date-time">{data.strapiNews.author.username}</span></p>
            <div className="social-icon">
              <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                <Image
                  className="logo-social lazyload"
                  alt="900x500"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  data-src={require ("../../../../images/png/facebook.jpg")}
                />
              </OutboundLink>
              <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                <Image
                  className="logo-social lazyload"
                  alt="900x500"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  data-src={require ("../../../../images/png/twitter.jpg")}
                />
              </OutboundLink>
              <OutboundLink href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                <Image
                  className="logo-social lazyload"
                  alt="900x500"
                  src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                  data-src={require ("../../../../images/png/google-plus.jpg")}
                />
              </OutboundLink>
            </div>
          </div>
          {image_1}
          <ReactMarkdown className="content-detail-news" source={data.strapiNews.content_1} />
          {image_2}
          <ReactMarkdown className="content-detail-news" source={data.strapiNews.content_2} />
          {image_3}
          <ReactMarkdown className="content-detail-news" source={data.strapiNews.content_3} />
        </div>
        <LatestNewsPage />
      </div>
    </Layout>
  )
}

export default DetailNewsTemplate

export const query = graphql`
  query DetailNewTemplate($url: String) {
    strapiNews(url: {eq: $url}) {
      title
      content_1
      content_2
      content_3
      image_header {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_content_1 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_content_2 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image_content_3 {
        childImageSharp {
          fixed(width: 2000) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      updated_at(formatString: "MMMM Do YYYY")
      author {
        id
        username
      }
    }
  }
`
