import React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import {
  Button,
  Form,
} from "react-bootstrap";
import { OutboundLink } from 'gatsby-plugin-gtag'

const LatestNewsPage = () => {
  const data = useStaticQuery(graphql`
    query LatestNewsQuery {
      allStrapiNews (
        sort: { order: DESC, fields: [updated_at] }
        limit: 3
      ) {
        edges {
          node {
            id
            image_header {
              childImageSharp {
                fixed(width: 2000) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            title
            url
            updated_at(formatString: "MMMM Do YYYY")
            author {
              username
            }
          }
        }
      }
    }
  `)

  return (
    <div className="wrapper-latest-news">
      <p className="title-news">Latest News</p>
      <ul>
        {data.allStrapiNews.edges.map(document => (
          <li key={document.node.id} className="box-latest-new">
            <h2>
              <OutboundLink href={`/${document.node.url}`}  className="name-item-news">{document.node.title}</OutboundLink>
            </h2>
            <div className="bottom-box-news">
              <p className="date-author">{document.node.updated_at}</p>
              <p className="date-author">{document.node.author.username}</p>
            </div>
          </li>
        ))}
      </ul>
      <div className="newsletter-signup">
        <p className="title-newsletter">Newsletter Sign up</p>
        <Form>
          <Form.Group controlId="formBasicEmail">
            <Form.Control type="name" placeholder="Email address" />
          </Form.Group>
          <div className="btn-contact">
            <Button type="submit" className="btn-submit">
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default LatestNewsPage
